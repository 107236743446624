import React, { Fragment } from 'react'
import track from 'react-tracking'
import SocialLinks from '../widgets/SocialLinks'
import { Link } from '../general/util/Links'
import './Footer.scss'

const Footer = () => {
  return (
    <footer styleName="background">
      <Fragment>
        <div styleName="container">
          <div styleName="content-row">
            <div styleName="content-column">
              <h6>Asiakaspalvelu</h6>
              <ul>
                <li>Digipalvelut (09) 156 6227</li>
                <li>Avoinna ma–pe 8–19</li>
              </ul>
              <ul>
                <li>Painettu lehti (09) 156 665</li>
                <li>Avoinna ma–pe 8–19</li>
              </ul>
              <ul>
                <li>Otavamedian vaihde (09) 156 61</li>
              </ul>
              <ul>
                <li>Sähköposti (digi)</li>
                <li>digi@otavamedia.fi</li>
                <li>Sähköposti</li>
                <li>asiakaspalvelu@otavamedia.fi</li>
              </ul>
            </div>
            <div styleName="content-column">
              <h6>Postiosoite</h6>
              <ul>
                <li>Pasilanraitio&nbsp;5</li>
                <li>00015 OTAVAMEDIA</li>
              </ul>
              <Link
                to={{ link: 'https://otavamedia.fi/tietosuoja/' }}>Tietoa evästeiden käytöstä</Link>
              <br/>
              <Link to={{ link: 'http://www.youronlinechoices.com/fi/' }}>Käyttäytymiseen perustuva mainonta</Link>
              <br/>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a href="" onClick={(e) => {
                window.gravitoCMP.openPreferences()
                e.preventDefault()
              }}>Evästeasetukset</a>
              <br/>
              <br/>
            </div>
            <div styleName="content-column">
              <h6>Mediatiedot</h6>
              <ul>
                <li><Link
                  to={{ link: 'https://yrityksille.otavamedia.fi/tekniset-tiedot-ja-hinnat/' }}>Tekniset tiedot, aikataulut ja ilmoitushinnat</Link>
                </li>
                <li><Link to={{ link: 'https://yrityksille.otavamedia.fi/reaaliaikainen-kayttajatieto/' }}>Tietoa verkon kävijöistä</Link></li>
                <li><Link to={{ link: 'https://otavamedia.fi/tietosuoja/' }}>Rekisteriseloste</Link></li>
                <li><Link to={{ link: '/verkkopalvelun-kayttoehdot/' }}>Käyttöehdot</Link></li>
              </ul>

              <h6>Tuotteet</h6>
              <ul>
                <li>
                  <Link to={{ link: 'https://otavamedia.fi/tuotteet-ja-edut/' }}>Aikakauslehdet</Link>
                </li>
                <li><Link to={{ link: 'https://otavamedia.fi/tuotteet-ja-edut/verkkopalvelut' }}>Verkkopalvelut</Link></li>
                <li><Link to={{ link: 'https://otavamedia.fi/tuotteet-ja-edut/digilehdet' }}>Digilehdet</Link></li>
              </ul>
            </div>
            <div styleName="content-column">
              <h6>Ota yhteyttä</h6>
              <ul>
                <li><Link to={{ link: '/anna-palautetta' }}>Palautelomake</Link></li>
                <li><Link to={{ link: 'https://asiointipalvelu.otavamedia.fi/omat/tilaukset' }}>Tilausmuutokset</Link></li>
                <li><Link to={{ link: '/usein-kysyttyja-kysymyksia/' }}>Usein kysytyt kysymykset</Link></li>
                <li>Sähköpostiosoitteet:</li>
                <li>etunimi.sukunimi@otava.fi</li>
              </ul>
              <br/>
              <h6>Sosiaalinen media</h6>
              <div styleName="social-links">
                <SocialLinks/>
              </div>
              <br/>
              <Link to={{ link: '/toimituksen-yhteystiedot/' }}
                    styleName="button">Toimitus</Link>
              <br/>
              <Link to={{ link: '/tilaa/' }}
                    onClick={(e) => {
                      let promoPosition = '-single'
                      if (window.location.pathname === '/') {
                        promoPosition = '-etusivu'
                      } else if (window.location.pathname.includes('/kategoria/') || window.location.pathname.includes('/avainsana/')) {
                        promoPosition = '-kategoria'
                      }
                      window.location.href = 'https://tilaus.metsastysjakalastus.fi/tilaus?promoID=OM_tilaa-painike&promoName=OM_MK&promoCreative=onlinetarjous&promoPosition=footer' + promoPosition
                      e.preventDefault()
                      return false
                    }}
                    styleName="button">Tilaa lehti</Link>
            </div>
          </div>

        </div>
      </Fragment>
    </footer>
  )
}

export default track({ gtmContext: ['Footer'] })(Footer)
